import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'HomeRoute',
        component: () => import('../views/Home.vue'),
      },
      {
        path: '/corporate-solutions',
        name: 'CorporateRoute',
        component: () => import('../views/CorporateView.vue')
      },
      {
        path: '/small-solutions',
        name: 'SmallRoute',
        component: () => import('../views/SmallView')
      },
      {
        path: '/how-we-do-it',
        name: 'HowWeDoItRoute',
        component: () => import('../views/HowWeDoView')
      },
      {
        path: '/get-in-touch',
        name: 'ContactUsRoute',
        component: () => import('../views/ContactUsView')
      },
      {
        path: '/lamaestra',
        name: 'LaMaestraRoute',
        component: () => import('../views/LaMaestra.vue')
      },
      {
        path: '/create-your-forest',
        name: 'CreateYourForestRoute',
        component: () => import('../views/CreateYourForest.vue')
      },
      {
        path: '/cases/asuaire',
        name: 'AsuaireCaseRoute',
        component: () => import('../views/cases/AsuaireView')
      },
      {
        path: '/cases/cctravel',
        name: 'CcTravelRoute',
        component: () => import('../views/cases/CcTravelView')
      },
      {
        path: '/cases/ccra',
        name: 'CcraRoute',
        component: () => import('../views/cases/CcraView')
      },
      {
        path: '/cases/sweetrush',
        name: 'SweetRushRoute',
        component: () => import('../views/cases/SweetRushView')
      },
      { 
        path: "/:pathMatch(.*)*", 
        name: 'NofFoundPage',
        component: () => import('../views/404View') 
      },
      { 
        path: "/privacy", 
        name: 'Privay',
        component: () => import('../views/PrivacyView') 
      },
      { 
        path: "/costaricagiveway", 
        name: 'Privay',
        component: () => import('../views/Costaricagiveway') 
      },
      {
        path: "/lamaestra/givebutter",
        component: () => import('../views/lamaestra/GiveButter.vue')
      }
    ]
  },
  {
    path: "/lamaestra/cctravel",
    component: () => import('../views/lamaestra/cctravel.vue')
  },
  {
    path: "/lamaestra/ccra",
    component: () => import('../views/lamaestra/ccra.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth', }
  },
})

export default router
